/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
require("./static/fonts.css")

exports.onClientEntry = () => {
  console.log("--------------------------------------------")
  console.log(
    "%c                    Made by",
    'text-align: center; color: #006cff;font-size: 18px; font-family: "Roboto", sans-serif'
  )
  console.log(
    "%cInvisual Branding Solutions",
    'color: white ; font-size: 24px; font-family: "Roboto", sans-serif'
  )
  console.log("--------------------------------------------")
//   const userLang = navigator.language

//   if (
//     userLang.includes("PT") &&
//     !window.location.pathname.startsWith("/pt") &&
//     !window.location.pathname.startsWith("/en") &&
//     !window.location.pathname.startsWith("/fr") &&
//     !window.location.pathname.startsWith("/es") &&
//     !window.location.pathname.startsWith("/pt-br")
//   ) {
//     window.location.pathname = `/pt${window.location.pathname}`
//   } else if (
//     userLang.includes("en") &&
//     !window.location.pathname.startsWith("/pt") &&
//     !window.location.pathname.startsWith("/en") &&
//     !window.location.pathname.startsWith("/fr") &&
//     !window.location.pathname.startsWith("/es") &&
//     !window.location.pathname.startsWith("/pt-br")
//   ) {
//     window.location.pathname = `/en${window.location.pathname}`
//   } else if (
//     userLang.includes("es") &&
//     !window.location.pathname.startsWith("/pt") &&
//     !window.location.pathname.startsWith("/en") &&
//     !window.location.pathname.startsWith("/fr") &&
//     !window.location.pathname.startsWith("/es") &&
//     !window.location.pathname.startsWith("/pt-br")
//   ) {
//     window.location.pathname = `/es${window.location.pathname}`
//   } else if (
//     userLang.includes("fr") &&
//     !window.location.pathname.startsWith("/pt") &&
//     !window.location.pathname.startsWith("/en") &&
//     !window.location.pathname.startsWith("/fr") &&
//     !window.location.pathname.startsWith("/es") &&
//     !window.location.pathname.startsWith("/pt-br")
//   ) {
//     window.location.pathname = `/fr${window.location.pathname}`
//   } else if (
//     userLang.includes("BR") &&
//     !window.location.pathname.startsWith("/pt") &&
//     !window.location.pathname.startsWith("/en") &&
//     !window.location.pathname.startsWith("/fr") &&
//     !window.location.pathname.startsWith("/es") &&
//     !window.location.pathname.startsWith("/pt-br")
//   ) {
//     window.location.pathname = `/pt-br${window.location.pathname}`
//   } else if (
//     !userLang.includes("PT") &&
//     !userLang.includes("en") &&
//     !userLang.includes("es") &&
//     !userLang.includes("fr") &&
//     !userLang.includes("BR") &&
//     !window.location.pathname.startsWith("/pt") &&
//     !window.location.pathname.startsWith("/en") &&
//     !window.location.pathname.startsWith("/fr") &&
//     !window.location.pathname.startsWith("/es") &&
//     !window.location.pathname.startsWith("/pt-br")
//   ) {
//     window.location.pathname = `/en${window.location.pathname}`
//   }
}
